



import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Footer from "./Footer";
import jsPDF from "jspdf";
import "jspdf-autotable";
import logopdf from './Assets/images/logopdf.jpg';

const Cart = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const selectedProducts = state?.selectedProducts || [];

  // State to track checked items
  const [checkedItems, setCheckedItems] = useState(
    selectedProducts.map((item) => ({ ...item, isChecked: true }))
  );

  // State for user details
  const [userDetails, setUserDetails] = useState({
    name: "",
    phone: "",
    address: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevDetails) => ({ ...prevDetails, [name]: value }));
  };

  const handleCheckboxChange = (index) => {
    const updatedItems = [...checkedItems];
    updatedItems[index].isChecked = !updatedItems[index].isChecked;
    setCheckedItems(updatedItems);
  };

  const generateWhatsAppMessage = () => {
    if (checkedItems.every((item) => !item.isChecked)) {
      alert("No items selected to send a message.");
      return "#";
    }
    const phoneNumber = "9026875429"; // Replace with Shawn's WhatsApp phone number
    const filteredProducts = checkedItems.filter((item) => item.isChecked);
    const message = filteredProducts
      .map(
        (item) =>
          `${item.name} - Quantity: ${item.quantity}, Size: ${item.size}`
      )
      .join("\n");
    const formattedMessage = encodeURIComponent(
      `Hi Sleevia, here is my Order:\n\n${message}`
    );
    return `https://wa.me/${phoneNumber}?text=${formattedMessage}`;
  };

  // Calculate the total amount
  const totalAmount = checkedItems.reduce((total, item) => {
    if (item.isChecked) {
      return total + item.quantity * 499;
    }
    return total;
  }, 0);

  // Generate PDF for the cart items
  const generatePDF = () => {
    if (!userDetails.name || !userDetails.phone || !userDetails.address) {
      alert("Please fill out all user details before generating the bill.");
      return;
    }

    if (checkedItems.every((item) => !item.isChecked)) {
      alert("No items selected to generate a bill.");
      return;
    }

    const doc = new jsPDF();

    // Add logo to the top right
    const margin = 10;
    doc.addImage(logopdf, 'JPEG', doc.internal.pageSize.width - 25 - margin, margin, 20, 20);

    // Title
    doc.setFontSize(18);
    doc.text("Sleevia", 85, 18);
    doc.text("Customer Invoice", 72, 28);

    // User Details
    doc.setFontSize(12);
    doc.text(`Customer Name: ${userDetails.name}`, 14, 40);
    doc.text(`Phone: ${userDetails.phone}`, 14, 50);
    doc.text(`Address: ${userDetails.address}`, 14, 60);

    // Table Data
    const tableData = checkedItems
      .filter((item) => item.isChecked)
      .map((item, index) => [
        index + 1,
        item.name,
        item.quantity,
        "₹499",
        `₹${item.quantity * 499}`,
      ]);

    doc.autoTable({
      head: [["#", "Product Name", "Quantity", "Price/Item", "Total"]],
      body: tableData,
      startY: 70,
    });

    // Total Amount
    doc.setFontSize(14);
    doc.text(
      `Total Payable Amount: ₹${totalAmount}`,
      14,
      doc.lastAutoTable.finalY + 10
    );

    // Footer
    doc.setFontSize(10);
    doc.text(
      "Thank you for shopping with Sleevia!",
      14,
      doc.lastAutoTable.finalY + 30
    );

    // Save the PDF
    doc.save("Sleevia_Bill.pdf");
  };

  return (
    <>
      <div className="p-4 mt-[17%]">
        <h2 className="text-center text-2xl mb-4 text-grey-900">Cart</h2>

        {/* User Details Form */}
        

        {checkedItems.length === 0 ? (
          <p className="">No items in the cart.</p>
        ) : (
          checkedItems.map((item, index) => (
            <div
              key={`${item.id}-${item.size}`}
              className="flex items-center justify-between mb-4 p-4 border border-gray-300 rounded-md shadow-lg"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "15px",
              }}
            >
              {/* Product Image and Name */}
              <div className="flex items-center gap-3">
                <img
                  src={item.images[0]} // Image from product added in cart
                  alt={item.name}
                  style={{
                    width: "80px",
                    height: "80px",
                    objectFit: "cover",
                    borderRadius: "8px",
                  }}
                />
                <p className="text-md font-semibold">{item.name}</p>
              </div>

              {/* Checkbox */}
              <div className="flex items-center gap-3">
                <input
                  type="checkbox"
                  checked={item.isChecked}
                  onChange={() => handleCheckboxChange(index)}
                  className="mr-2 transform scale-150"
                />
              </div>

              {/* Size and Quantity */}
              <div className="flex items-center gap-4">
                <p className="text-sm text-gray-600">Size: {item.size}</p>
                <p className="text-sm text-gray-600">Quantity: {item.quantity}</p>
              </div>
            </div>
          ))
        )}
        <div className="flex flex-row justify-between mt-5 p-4 bg-white border border-gray-200 rounded-lg shadow-lg w-full max-w-md mx-auto">
          <p className="text-lg text-gray-800">Total Payable Amount</p>
          <p className="text-[#FF5722]">₹{totalAmount}</p>
        </div>

        <div className="mt-8 flex flex-col items-center gap-4">
          <a
            href="/products"
            className="text-[#FFB400] cursor-pointer underline"
          >
            Back to Products
          </a>
          <a
            href={generateWhatsAppMessage()}
            target="_blank"
            rel="noopener noreferrer"
            className="py-2 px-6 text-lg bg-[#25D366] text-white rounded-md text-center inline-block flex items-center gap-2 hover:bg-[#128C7E] transition-all"
          >
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
              alt="WhatsApp Icon"
              className="w-5 h-5"
            />
            Order via WhatsApp
          </a>
          
          <div className="mb-6 p-4 border border-gray-300 rounded-md shadow-lg">
          <h3 className="text-lg font-semibold mb-3">Enter Your Details</h3>
          <input
            type="text"
            name="name"
            value={userDetails.name}
            onChange={handleInputChange}
            placeholder="Your Name"
            className="w-full mb-3 p-2 border rounded-md"
          />
          <input
            type="text"
            name="phone"
            value={userDetails.phone}
            onChange={handleInputChange}
            placeholder="Your Phone Number"
            className="w-full mb-3 p-2 border rounded-md"
          />
          <textarea
            name="address"
            value={userDetails.address}
            onChange={handleInputChange}
            placeholder="Your Address"
            className="w-full p-2 border rounded-md"
          ></textarea>
        </div>
          {/* Generate PDF Button */}
          <button
            onClick={generatePDF}
            className="mt-4 py-2 px-6 bg-blue-500 text-white text-lg rounded-md hover:bg-blue-600 transition-all"
          >
            Generate Bill (PDF)
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Cart;
