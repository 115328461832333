
// import React, { useState } from "react";

// const NavBar = ({ scrollToProducts }) => {
//   const [isOpen, setIsOpen] = useState(false);

//   // Toggle hamburger menu
//   const toggleMenu = () => {
//     setIsOpen(!isOpen);
//   };

//   return (
//     <nav className="bg-black text-white shadow-md fixed w-full top-0 left-0 z-50">
//       <div className="max-w-screen-xl mx-auto px-4 py-4">
//         <div className="flex items-center justify-between">
//           {/* Logo Section */}
//           <div className="text-3xl font-bold text-orange-200">
//             <a href="/">Sleevia</a>
//           </div>

//           {/* Hamburger Icon */}
//           <div
//             className="lg:hidden flex items-center cursor-pointer"
//             onClick={toggleMenu}
//           >
//             <div
//               className={`relative h-8 w-8 flex items-center justify-center rounded-full bg-orange-400 text-black transition-all duration-500 ease-in-out ${
//                 isOpen ? "rotate-90" : ""
//               }`}
//             >
//               {isOpen ? (
//                 <span className="font-bold text-xl">×</span> // Close icon
//               ) : (
//                 <span className="font-bold text-xl">≡</span> // Menu icon
//               )}
//             </div>
//           </div>

//           {/* Desktop Menu */}
//           <div className="hidden lg:flex space-x-8">
//             <a
//               href="/"
//               className="text-lg hover:text-orange-500 transition-colors duration-300"
//             >
//               Home
//             </a>
//             <a
//               onClick={scrollToProducts}
//               className="cursor-pointer text-lg hover:text-orange-500 transition-colors duration-300"
//             >
//               Products
//             </a>
//             <a
//               href="/aboutus"
//               className="text-lg hover:text-orange-500 transition-colors duration-300"
//             >
//               About
//             </a>
//             <a
//               href="contact"
//               className="text-lg hover:text-orange-500 transition-colors duration-300"
//             >
//               Contact
//             </a>
//           </div>
//         </div>

//         {/* Mobile Menu */}
//         <div
//           className={`lg:hidden absolute top-16 left-0 w-full bg-black text-white transition-all duration-700 ease-in-out ${
//             isOpen
//               ? "h-auto opacity-100 translate-y-0 scale-100"
//               : "h-0 opacity-0 overflow-hidden translate-y-10 scale-95"
//           }`}
//         >
//           <div className="flex flex-col items-center py-4 space-y-4">
//             <a
//               href="/"
//               className={`text-lg hover:text-orange-500 transition-colors duration-300 ${!isOpen ? 'pointer-events-none' : ''}`}
//             >
//               Home
//             </a>
//             <a
//               href="/products"
//               className={`text-lg hover:text-orange-500 transition-colors duration-300 ${!isOpen ? 'pointer-events-none' : ''}`}
//             >
//               Products
//             </a>
//             <a
//               href="/aboutus"
//               className={`text-lg hover:text-orange-500 transition-colors duration-300 ${!isOpen ? 'pointer-events-none' : ''}`}
//             >
//               About
//             </a>
//             <a
//               href="/contact"
//               className={`text-lg hover:text-orange-500 transition-colors duration-300 ${!isOpen ? 'pointer-events-none' : ''}`}
//             >
//               Contact
//             </a>
//           </div>
//         </div>
//       </div>
//     </nav>
//   );
// };

// export default NavBar;


import React, { useState } from "react";
import { ShoppingCart } from "@mui/icons-material"; // Import ShoppingCart icon
import { useNavigate } from "react-router-dom";
import { useCart } from "./CartContext"; // Use CartContext
import { Typography } from "@mui/material";
import logo from "./Assets/images/logo.png"
import CartIcon from "./CartIcon";
const NavBar = ({ scrollToProducts }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  // Use the shared cart state from the context
  const { selectedProducts, getTotalItemsInCart } = useCart();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-black text-white shadow-md fixed w-full top-0 left-0 z-50">
      <div className="max-w-screen-xl mx-auto px-4 py-4">
        <div className="flex items-center justify-between align-center px-4 sm:px-6 md:px-8">
          {/* Logo Section */}
          <div className="flex items-center space-x-2">
            <a href="/">
              <img
                src={logo}
                alt="SLEEVIA Logo"
                className="h-8 w-auto sm:h-10 md:h-12" // Adjust height for different screens
              />
            </a>
            <Typography
              variant="h6"
              className="font-bold text-white text-lg sm:text-xl md:text-2xl" // Adjust font size for responsiveness
              sx={{ textAlign: "center" }}
            >
              SLEEVIA
            </Typography>
          </div>



          <div className="flex ">
            {/* Cart Icon */}
            
            <CartIcon className="lg:hidden"/>

            {/* Hamburger Icon */}
            <div
              className="lg:hidden flex items-center cursor-pointer"
              onClick={toggleMenu}
            >
              <div
                className={`relative h-8 w-8 flex items-center justify-center rounded-full bg-[#FFB400] text-white transition-all duration-500 ease-in-out ${isOpen ? "rotate-90" : ""
                  }`}
              >
                {isOpen ? (
                  <span className="font-bold text-xl">×</span> // Close icon
                ) : (
                  <span className="font-bold text-xl">≡</span> // Menu icon
                )}
              </div>
            </div>
          </div>

          {/* Desktop Menu */}
          <div className="hidden lg:flex space-x-8">
            <a
              href="/"
              className="text-lg hover:text-orange-500 transition-colors duration-300"
            >
              Home
            </a>
            <a
              href="/products"
              className="cursor-pointer text-lg hover:text-orange-500 transition-colors duration-300"
            >
              Products
            </a>
            <a
              href="/aboutus"
              className="text-lg hover:text-orange-500 transition-colors duration-300"
            >
              About
            </a>
            <a
              href="/contact"
              className="text-lg hover:text-orange-500 transition-colors duration-300"
            >
              Contact
            </a>
            <CartIcon/>
          </div>
        </div>

        {/* Mobile Menu */}
        <div
          className={`lg:hidden absolute top-16 left-0 w-full bg-black text-white transition-all duration-700 ease-in-out ${isOpen
              ? "h-auto opacity-100 translate-y-0 scale-100"
              : "h-0 opacity-0 overflow-hidden translate-y-10 scale-95"
            }`}
        >

          <div className="flex flex-col items-center py-4 space-y-4">
            <a
              href="/"
              className={`text-lg hover:text-orange-500 transition-colors duration-300 ${!isOpen ? 'pointer-events-none' : ''}`}
            >
              Home
            </a>
            <a
              href="/products"
              className={`text-lg hover:text-orange-500 transition-colors duration-300 ${!isOpen ? 'pointer-events-none' : ''}`}
            >
              Products
            </a>
            <a
              href="/aboutus"
              className={`text-lg hover:text-orange-500 transition-colors duration-300 ${!isOpen ? 'pointer-events-none' : ''}`}
            >
              About
            </a>
            <a
              href="/contact"
              className={`text-lg hover:text-orange-500 transition-colors duration-300 ${!isOpen ? 'pointer-events-none' : ''}`}
            >
              Contact
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
