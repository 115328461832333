import React from "react";
import { useNavigate } from "react-router-dom";
import gokub1 from './Assets/images/gokub1.jpg';
import itachi from './Assets/images/itachi.png';
import kakashi from './Assets/images/kakashi.png';
import gojo from './Assets/images/gojo.png';
import pain from './Assets/images/pain.png';
import potter1 from './Assets/images/potter1.png';
import luffy from './Assets/images/luffy.png';
import min1 from './Assets/images/min1.png';
import tech2 from './Assets/images/tech2.png';
import monday2 from './Assets/images/monday2.png';
import mb2 from './Assets/images/mb2.png';
import lavender from './Assets/images/lavender.png';
import astronautf1 from './Assets/images/astronautf1.png';
import pandaf1 from './Assets/images/pandaf1.png';
import musicf1 from './Assets/images/musicf1.png';
import { Link } from "react-router-dom";

const products = [
  { id: 10, name: "Black printed oversized T-shirt", price: "Rs 499", image: tech2 },
  { id: 11, name: "Black printed oversized T-shirt", price: "Rs 499", image: monday2 },
  { id: 12, name: "Black printed oversized T-shirt", price: "Rs 499", image: mb2 },
  { id: 13, name: "Astronaut printed oversized T-shirt", price: "Rs 499", image: astronautf1 },
  { id: 14, name: "Panda printed oversized T-shirt", price: "Rs 499", image: pandaf1 },
  { id: 15, name: "Lavender printed oversized T-shirt", price: "Rs 499", image: musicf1 },
  { id: 1, name: "White printed oversized T-shirt", price: "Rs 499", image: min1 },
  { id: 9, name: "Goku Oversized T-Shirt", price: "Rs 499", image: gokub1 },
  { id: 2, name: "Lavender Oversized T-Shirt", price: "Rs 499", image: lavender },
  { id: 3, name: "Itachi Oversized T-shirt", price: "Rs 499", image: itachi },
  { id: 4, name: "Kakashi Oversized T-shirt", price: "Rs 499", image: kakashi },
  { id: 5, name: "Satoru Gojo Oversized T-shirt", price: "Rs 499", image: gojo },
  { id: 6, name: "Harry Potter Oversized T-shirt", price: "Rs 499", image: potter1 },
  { id: 7, name: "Pain Oversized T-shirt", price: "Rs 499", image: pain },
  { id: 8, name: "Luffy Gear 5 Oversized T-shirt", price: "Rs 499", image: luffy },
  
];

const ProductsPage = () => {
  const navigate = useNavigate();

  const handleProductClick = (id) => {
    navigate(`/product/${id}`);
  };

  return (
    <div className="p-4 bg-gray-100" >
      <h2 className="text-center text-2xl mb-4 text-grey-900">Our Products</h2>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 ">
        {products.map((product) => (
          <div
            key={product.id}
            className="bg-white p-4 rounded-lg shadow-lg cursor-pointer border"
            onClick={() => handleProductClick(product.id)}
          >
            <img
              src={product.image}
              alt={product.name}
              className="w-full h-32 object-cover rounded-md"
            />
            <h3 className="text-lg font-semibold mt-2 text-gray-800">{product.name}</h3>
            <div className="flex align-center justify-between">
            <p className="text-gray-600">{product.price}</p><Link to={`/product/${product.id}`} className="text-[#FFB400]">View Details</Link>  
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductsPage;
