import React, { useState } from "react";
import { Box, Typography, Select, MenuItem, InputLabel, FormControl, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useCart } from "./CartContext";
import { useParams } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import Footer from "./Footer";
import SimilarProducts from "./SimilarProduct";
import itachi from './Assets/images/itachi.png';
import kakashi from './Assets/images/kakashi.png';
import gojo from './Assets/images/gojo.png';
import pain from './Assets/images/pain.png';
import potter1 from './Assets/images/potter1.png';
import itachif1 from './Assets/images/itachif1.jpg';
import itachib1 from './Assets/images/itachib1.jpg';
import kakashif1 from './Assets/images/kakashif1.jpg';
import kakashib1 from './Assets/images/kakashib1.jpg';
import painf1 from './Assets/images/painf1.jpg';
import painb1 from './Assets/images/painb1.jpg';
import gokuf1 from './Assets/images/gokuf1.jpg';
import gokub1 from './Assets/images/gokub1.jpg';
import gokup1 from './Assets/images/gokup1.jpg';
import lavenderb1 from './Assets/images/lavenderb1.jpg';
import lavenderb2 from './Assets/images/lavenderb2.jpg';
import lavenderf1 from './Assets/images/lavenderf1.jpg';
import gojof1 from './Assets/images/gojof1.jpg';
import gojob1 from './Assets/images/gojob1.jpg';
import harryb1 from './Assets/images/harryb1.jpg';
import harryb2 from './Assets/images/harryb2.jpg';
import harryf1 from './Assets/images/harryf1.jpg';
import luffyf1 from './Assets/images/luffyf1.jpg';
import luffyb1 from './Assets/images/luffyb1.jpg';
import luffy from './Assets/images/luffy.png';
import min1 from './Assets/images/min1.png';
import min2 from './Assets/images/min2.png';
import min3 from './Assets/images/min3.png';
import min4 from './Assets/images/min4.png';
import min5 from './Assets/images/min5.png';
import min6 from './Assets/images/min6.png';
import mb1 from './Assets/images/mb1.png';
import mb2 from './Assets/images/mb2.png';
import tech1 from './Assets/images/tech1.png';
import tech2 from './Assets/images/tech2.png';
import astronautf1 from './Assets/images/astronautf1.png';
import astronautb1 from './Assets/images/astronautb1.png';
import astronautf2 from './Assets/images/astronautf2.png';
import astronautb2 from './Assets/images/astronautb2.png';
import pandaf1 from './Assets/images/pandaf1.png';
import pandaf2 from './Assets/images/pandaf2.png';
import pandab1 from './Assets/images/pandab1.png';
import pandab2 from './Assets/images/pandab2.png';
import musicf1 from './Assets/images/musicf1.png';
import musicb1 from './Assets/images/musicb1.png';
import monday1 from './Assets/images/monday1.png';
import monday2 from './Assets/images/monday2.png';


const Details = () => {
    // Extract product ID from the route
    const { id } = useParams();

    // Example product data (this can be replaced with an API call)
    const products = [
        {
            id: 13, name: "Astronaut oversized T-shirt", price: "Rs 449", offer: "Flat 10% Off", sizes: ["XS", "S", "M", "L", "XL"], images: [
                
                astronautf1,
                astronautb1,
                astronautf2,
                astronautb2,
            ],
            details: [
                "Material: 100% Cotton",
                "Available Sizes: XS, S, M, L, XL",
                "Care Instructions: Machine Washable",
                "Unisex design suitable for all.",
            ],
        },
        {
            id: 14, name: "Panda oversized T-shirt", price: "Rs 449", offer: "Flat 10% Off", sizes: ["XS", "S", "M", "L", "XL"], images: [
                
                pandaf1,
                pandab1,
                pandaf2,
                pandab2
            ],
            details: [
                "Material: 100% Cotton",
                "Available Sizes: XS, S, M, L, XL",
                "Care Instructions: Machine Washable",
                "Unisex design suitable for all.",
            ],
        },
        {
            id: 15, name: "Lavender printed oversized T-shirt", price: "Rs 449", offer: "Flat 10% Off", sizes: ["XS", "S", "M", "L", "XL"], images: [
                
                musicf1,
                musicb1
            ],
            details: [
                "Material: 100% Cotton",
                "Available Sizes: XS, S, M, L, XL",
                "Care Instructions: Machine Washable",
                "Unisex design suitable for all.",
            ],
        },
        {
            id: 10, name: "Black printed oversized T-shirt", price: "Rs 449", offer: "Flat 10% Off", sizes: ["XS", "S", "M", "L", "XL"], images: [
                
                monday1,
                monday2,
            ],
            details: [
                "Material: 100% Cotton",
                "Available Sizes: XS, S, M, L, XL",
                "Care Instructions: Machine Washable",
                "Unisex design suitable for all.",
            ],
        },
        {
            id: 11, name: "Black printed oversized T-shirt", price: "Rs 449", offer: "Flat 10% Off", sizes: ["XS", "S", "M", "L", "XL"], images: [
                 
                mb1,
                mb2
            ],
            details: [
                "Material: 100% Cotton",
                "Available Sizes: XS, S, M, L, XL",
                "Care Instructions: Machine Washable",
                "Unisex design suitable for all.",
            ],
        },
        {
            id: 12, name: "White printed oversized T-shirt", price: "Rs 449", offer: "Flat 10% Off", sizes: ["XS", "S", "M", "L", "XL"], images: [
                
                tech1,
                tech2,
            ],
            details: [
                "Material: 100% Cotton",
                "Available Sizes: XS, S, M, L, XL",
                "Care Instructions: Machine Washable",
                "Unisex design suitable for all.",
            ],
        },
        {
            id: 1, name: "White printed oversized T-shirt", price: "Rs 449", offer: "Flat 10% Off", sizes: ["XS", "S", "M", "L", "XL"], images: [
                
                min1,
                min2,
                min3,
                min4,
                min5,
                min6,
            ],
            details: [
                "Material: 100% Cotton",
                "Available Sizes: XS, S, M, L, XL",
                "Care Instructions: Machine Washable",
                "Unisex design suitable for all.",
            ],
        },
        {
            id: 9, name: "Goku Oversized T-Shirt", price: "Rs 499", offer: "Flat 10% Off", sizes: ["XS", "S", "M", "L", "XL"], images: [
                
                gokub1,
                gokuf1,
                gokup1
            ],
            details: [
                "Material: 100% Cotton",
                "Available Sizes: S, M, L, XL",
                "Care Instructions: Machine Washable",
                "Unisex design suitable for all.",
            ],
        },
        {
            id: 2, name: "Lavender Oversized T-Shirt", price: "Rs 499", offer: "Flat 10% Off", sizes: ["XS", "S", "M", "L", "XL"], images: [
                              
                lavenderb2,
                lavenderb1,
                lavenderf1,
            

            ],
            details: [
                "Material: 100% Cotton",
                "Available Sizes: S, M, L, XL",
                "Care Instructions: Machine Washable",
                "Unisex design suitable for all.",
            ],
        },
        {
            id: 3, name: "Itachi Oversized T-shirt", price: "Rs 499", offer: "Flat 10% Off", sizes: ["XS", "S", "M", "L", "XL"], images: [
                itachi,
                itachib1,
                itachif1,
                
            ],
            details: [
                "Material: 100% Cotton",
                "Available Sizes: S, M, L, XL",
                "Care Instructions: Machine Washable",
                "Unisex design suitable for all.",
            ],
        },
        {
            id: 4, name: "Kakashi Oversized T-shirt", price: "Rs 499", offer: "Flat 10% Off", sizes: ["XS", "S", "M", "L", "XL"], images: [
                kakashi,
                kakashib1,
                kakashif1,
                
            ],
            details: [
                "Material: 100% Cotton",
                "Available Sizes: S, M, L, XL",
                "Care Instructions: Machine Washable",
                "Unisex design suitable for all.",
            ],
        },
        {
            id: 5, name: "Satoru Gojo Oversized T-shirt", price: "Rs 499", offer: "Flat 10% Off", sizes: ["XS", "S", "M", "L", "XL"], images: [
                gojo,
                gojob1,
                gojof1,
                
            ],
            details: [
                "Material: 100% Cotton",
                "Available Sizes: S, M, L, XL",
                "Care Instructions: Machine Washable",
                "Unisex design suitable for all.",
            ],
        },
        {
            id: 6, name: "Harry Potter Oversized T-shirt", price: "Rs 499", offer: "Flat 10% Off", sizes: ["XS", "S", "M", "L", "XL"], images: [
                harryf1,
               harryb2,
               harryb1,
               
            ],
            details: [
                "Material: 100% Cotton",
                "Available Sizes: S, M, L, XL",
                "Care Instructions: Machine Washable",
                "Unisex design suitable for all.",
            ],
        },
        {
            id: 7, name: "Pain || Naruto Oversized T-shirt", price: "Rs 499", offer: "Flat 10% Off", sizes: ["XS", "S", "M", "L", "XL"], images: [
                pain,
               painb1,
               painf1,
               
            ],
            details: [
                "Material: 100% Cotton",
                "Available Sizes: S, M, L, XL",
                "Care Instructions: Machine Washable",
                "Unisex design suitable for all.",
            ],
        },
        {
            id: 8, name: "Luffy Gear 5 Oversized T-shirt", price: "Rs 499", offer: "Flat 10% Off", sizes: ["XS", "S", "M", "L", "XL"], images: [
                luffy,
               luffyb1,
               luffyf1,
              
            ],
            details: [
                "Material: 100% Cotton",
                "Available Sizes: S, M, L, XL",
                "Care Instructions: Machine Washable",
                "Unisex design suitable for all.",
            ],
        },
    ];  

    const { selectedProducts, handleAddToCart } = useCart(); // Use CartContext to manage cart data
    const navigate = useNavigate();

    // Find the specific product by ID
    const product = products.find((p) => p.id === parseInt(id));

    const [quantityMap, setQuantityMap] = useState({});
    const [disabledButtons, setDisabledButtons] = useState({}); // Track disabled state for each product
    const [showDetails, setShowDetails] = useState(false);
    const [selectedSizes, setSelectedSizes] = useState({});

const handleSizeChange = (productId, size) => {
    setSelectedSizes((prevState) => ({
        ...prevState,
        [productId]: size,
    }));
};

    const handleQuantityChange = (productId, increment) => {
        setQuantityMap((prevState) => ({
            ...prevState,
            [productId]: Math.max(1, (prevState[productId] || 1) + increment),
        }));
    };

    const getTotalItemsInCart = () => {
        return selectedProducts.reduce((total, product) => total + product.quantity, 0);
    };

    const handleAddToCartClick = (product, size, quantity) => {
        handleAddToCart(product, size, quantity);

        // Disable the button for this product after adding to cart
        setDisabledButtons((prevState) => ({
            ...prevState,
            [product.id]: true,
        }));
    };

    if (!product) {
        return <Typography>Product not found.</Typography>;
    }

    return (
        <>
            <Box sx={{ padding: "16px", maxWidth: "600px", marginTop: "20%" }}>
                {/* Image Slider */}
                <Box sx={{ marginBottom: "16px" }}>
                    <SwipeableViews>
                        {product.images.map((image, index) => (
                            <Box key={index} sx={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#f9f9f9" }}>
                                <img src={image} alt={`Product Image ${index + 1}`} style={{ maxWidth: "100%", height: "auto", borderRadius: "8px" }} />
                            </Box>
                        ))}
                    </SwipeableViews>
                </Box>
                            <Box sx={{ display: "flex", justifyContent: "space-around", alignItems: "center", padding: "8px 0 ", borderBottom: "1px solid #ddd", margin: "0 auto" }}>
                            <Typography variant="body1" sx={{ fontWeight: "bold", color: "#333" }}>
                        {product.name}
                    </Typography>
                            </Box>
                {/* Price and Offer */}
                <Box sx={{ display: "flex", justifyContent: "space-around", alignItems: "center", padding: "8px 0 ", borderBottom: "1px solid #ddd", margin: "0 auto" }}>
                    <Typography variant="body1" sx={{ fontWeight: "bold", color: "#333" }}>
                        {product.price}
                    </Typography>
                    
                </Box>

                {/* Product Quantity and Size */}
                <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "space-between", gap: 2, margin: 2 }}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                        <FormControl sx={{ minWidth: 80 }}>
                            <InputLabel>Size</InputLabel>
                            <Select
        value={selectedSizes[product.id] || product.sizes[0]} // Use selected size for this product, default to the first size if none selected
        onChange={(e) => handleSizeChange(product.id, e.target.value)} // Update selected size on change
        label="Size"
        sx={{
          padding: "0",
          "& .MuiSelect-select": { padding: "5px 14px" },
        }}
      >
        {product.sizes.map((size) => (
          <MenuItem key={size} value={size}>
            {size}
          </MenuItem>
        ))}
      </Select>
                        </FormControl>
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                            <button onClick={() => handleQuantityChange(product.id, -1)} disabled={quantityMap[product.id] <= 1} className="px-3 py-1 border border-gray-300 rounded">-</button>
                            <Typography>{quantityMap[product.id] || 1}</Typography>
                            <button onClick={() => handleQuantityChange(product.id, 1)} className="px-2.5 py-1 border border-gray-300 rounded">+</button>
                        </Box>


                    </Box>
                    <Box sx={{ marginTop: "16px", display: "flex", justifyContent: "center" }}>
                        <Button
                            onClick={() => setShowDetails(!showDetails)}
                            sx={{ fontSize: "12px" }}
                            endIcon={showDetails ? <ExpandLess /> : <ExpandMore />}
                        >
                            {showDetails ? "Hide Details" : "View Details"}
                        </Button>

                        {showDetails && (
                            <Box
                                sx={{
                                    marginTop: "8px",
                                    padding: "12px",
                                    backgroundColor: "#f4f4f4",
                                    borderRadius: "8px",
                                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                }}
                            >
                                {product.details.map((detail, index) => (
                                    <Typography key={index} sx={{ fontSize: "14px", marginBottom: "8px" }}>
                                        - {detail}
                                    </Typography>
                                ))}
                            </Box>
                        )}
                    </Box>
                </Box>

                {/* Add to Cart and Go to Cart Buttons */}
                <Box sx={{ display: "flex", gap: 2, justifyContent: "space-around", alignItems: "flex-end" }}>

                <a
    onClick={() =>
        handleAddToCartClick(
            product,
            selectedSizes[product.id] || product.sizes[0], // Default to the first size if no size is selected
            quantityMap[product.id] || 1
        )
    }
    className={`px-4 py-2 text-white bg-[#FFB400] rounded hover:bg-[#FFB400] cursor-pointer ${disabledButtons[product.id] ? "opacity-50 cursor-not-allowed" : ""}`}
    style={{ pointerEvents: disabledButtons[product.id] ? "none" : "auto" }}
>
    {disabledButtons[product.id] ? "Added" : "Add to Cart"}
</a>
                    <a
                        onClick={() => navigate("/cart", { state: { selectedProducts } })}
                        className="text-[#FFB400] cursor-pointer underline cursor-pointer"
                    >
                        Go to Cart
                        {getTotalItemsInCart() > 0 && (
                            <Typography
                                sx={{
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    fontSize: "12px",
                                    color: "white",
                                    background: "red",
                                    borderRadius: "50%",
                                    padding: "2px 6px",
                                }}
                            >
                                {getTotalItemsInCart()}
                            </Typography>
                        )}
                    </a>


                </Box>

                {/* Product Details */}



                {/* Similar Products */}



            </Box>
            <SimilarProducts />
            <Footer />
        </>
    );
};

export default Details;
