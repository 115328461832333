import React from "react";
import Design from './Designname';
import ProductsPage from './Collection';
import Cover from "./Cover";
import Footer from "./Footer";



const Home = () => {


 

  return (
    <div>
        <Cover/>
    <Design />
      <ProductsPage />
      <Footer/>
      
    </div>
  );

  
};

export default Home;



