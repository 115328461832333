import React, { createContext, useState, useContext } from 'react';

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [selectedProducts, setSelectedProducts] = useState([]);

  const handleAddToCart = (product, size, quantity) => {
    const existingProductIndex = selectedProducts.findIndex(
      (item) => item.id === product.id && item.size === size
    );

    if (existingProductIndex !== -1) {
      const updatedProducts = [...selectedProducts];
      updatedProducts[existingProductIndex].quantity += quantity;
      setSelectedProducts(updatedProducts);
    } else {
      const updatedProduct = { ...product, size, quantity };
      setSelectedProducts([...selectedProducts, updatedProduct]);
    }
  };

  const getTotalItemsInCart = () => {
    return selectedProducts.reduce((total, product) => total + product.quantity, 0);
  };

  return (
    <CartContext.Provider value={{ selectedProducts, handleAddToCart, getTotalItemsInCart }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);
