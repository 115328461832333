// // ProductDetailsPage.js
// import React from "react";
// import { useParams, useNavigate } from "react-router-dom";

// const products = [
//   { id: 1, name: "Naruto T-Shirt", price: "$20", description: "High-quality cotton Naruto-themed t-shirt", image: "https://via.placeholder.com/150" },
//   { id: 2, name: "Goku Hoodie", price: "$30", description: "Cozy hoodie with a Goku design", image: "https://via.placeholder.com/150" },
//   { id: 3, name: "Anime Mug", price: "$10", description: "A perfect anime-themed mug for your coffee", image: "https://via.placeholder.com/150" },
//   { id: 4, name: "Sasuke Jacket", price: "$50", description: "Premium Sasuke design jacket", image: "https://via.placeholder.com/150" },
//   { id: 5, name: "One Piece Hat", price: "$15", description: "Stylish One Piece-themed hat", image: "https://via.placeholder.com/150" },
//   { id: 6, name: "Demon Slayer Pants", price: "$40", description: "Comfortable pants with Demon Slayer design", image: "https://via.placeholder.com/150" },
// ];

// const ProductDetailsPage = () => {
//   const { id } = useParams();
//   const navigate = useNavigate();
//   const product = products.find((p) => p.id === parseInt(id));

//   if (!product) {
//     return <p className="text-center text-red-500">Product not found</p>;
//   }

//   return (
//     <div className="p-4 bg-gray-100">
//       <button
//         className="text-blue-500 mb-4"
//         onClick={() => navigate(-1)} // Navigate back to the previous page
//       >
//         &larr; Back
//       </button>
//       <div className="bg-white p-6 rounded-lg shadow-lg">
//         <img
//           src={product.image}
//           alt={product.name}
//           className="w-full h-64 object-cover rounded-md mb-4"
//         />
//         <h2 className="text-2xl font-bold text-gray-800 mb-2">{product.name}</h2>
//         <p className="text-gray-600 text-lg mb-4">{product.price}</p>
//         <p className="text-gray-700">{product.description}</p>
//       </div>
//     </div>
//   );
// };

// export default ProductDetailsPage;

import React from "react";
import ProductsPage from "./Collection";
import Footer from "./Footer";

const OurProducts = () => {

  return (
    <div style={{ marginTop: "15%" }}>
    <ProductsPage/> 
    <Footer/>

    </div>
);
 };
export default OurProducts;
