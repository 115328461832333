import React from "react";
import { Box, Typography, Link, IconButton } from "@mui/material";
import { Instagram } from "@mui/icons-material";

const Footer = () => {
  return (
    <Box
      component="footer"
      className="bg-gray-900 text-white"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        py: 4,
        px: 2,
        
      }}
    >
      {/* Logo or Brand Name */}
      <Typography
        variant="h6"
        className="font-bold"
        sx={{ textAlign: "center", mb: 2 }}
      >
        SLEEVIA
      </Typography>

      {/* Links Section */}
      <Box
  sx={{
    display: "flex",
    justifyContent: "center",
    gap: 4,
    flexWrap: "wrap",
    mb: 4,
  }}
>
  <Link
    href="/"
    underline="none"
    sx={{
      color: "#FFB400", // Hex color code for orange
      textDecoration: "none",
      "&:hover": {
        color: "#FFFFFF", // Hex code for white
      },
    }}
  >
    Home
  </Link>
  <Link
    href="/Aboutus"
    underline="none"
    sx={{
      color: "#FFB400",
      textDecoration: "none",
      "&:hover": {
        color: "#FFFFFF",
      },
    }}
  >
    About Us
  </Link>
  <Link
    href="/products"
    underline="none"
    sx={{
      color: "#FFB400",
      textDecoration: "none",
      "&:hover": {
        color: "#FFFFFF",
      },
    }}
  >
    Products
  </Link>
  <Link
    href="contact"
    underline="none"
    sx={{
      color: "#FFB400",
      textDecoration: "none",
      "&:hover": {
        color: "#FFFFFF",
      },
    }}
  >
    Contact
  </Link>
</Box>

      {/* Social Media Icons */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: 2,
          mb: 4,
        }}
      >
        <IconButton aria-label="Instagram" component="a" href="https://www.instagram.com/sleeviaofficial/" target="_blank" rel="noopener noreferrer">
  <Instagram
    sx={{
      background: "linear-gradient(45deg, #833ab4, #fd1d1d, #fcb045)",
      borderRadius: "50%",
      color: "white",
      padding: "5px",
    }}
  />
</IconButton>

      </Box>

      {/* Copyright Section */}
      <Typography
        variant="body2"
        className="text-gray-400"
        sx={{ textAlign: "center" }}
      >
        © {new Date().getFullYear()} SLEEVIA. All rights reserved.
      </Typography>
    </Box>
  );
};

export default Footer;
