import React, { useState } from "react";

const Practice = () => {
  const [contacts, setContacts] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    mobile: "",
  });

  const [tanisha, setTanisha] = useState(false);

  const handleClick = () => {
    setTanisha(true);
  }
  const handleback = () => {
    setTanisha(false);
  }
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const addContact = () => {
    if (formData.name && formData.surname && formData.mobile) {
      const newContacts = [...contacts, formData];
      newContacts.sort((a, b) => a.name.localeCompare(b.name)); // Sorting by name
      setContacts(newContacts);
      setFormData({ name: "", surname: "", mobile: "" }); // Reset Form
    }
  };

  return (
    <>
      <div className="flex flex-col items-center p-6 bg-gray-100 min-h-screen">
        <h2 className="text-3xl font-bold text-blue-600 mb-4">Phonebook</h2>

        {/* Form Section */}
        <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-md">
          <div className="mb-4">
            <label className="block text-gray-700 font-medium">Name:</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full p-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
              placeholder="Enter First Name"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-medium">Surname:</label>
            <input
              type="text"
              name="surname"
              value={formData.surname}
              onChange={handleChange}
              className="w-full p-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
              placeholder="Enter Surname"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-medium">Mobile No:</label>
            <input
              type="tel"
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
              className="w-full p-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
              placeholder="Enter Mobile Number"
            />
          </div>
          <button
            onClick={addContact}
            className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-700 transition"
          >
            Add to Contact
          </button>
        </div>

        {/* Contact List */}
        <div className="m-6 w-full max-w-md">
          <h3 className="text-xl font-semibold text-gray-700 mb-2">Contact List</h3>
          <ul className="bg-white p-4 rounded-lg shadow-md">
            {contacts.length > 0 ? (
              contacts.map((contact, index) => (
                <li
                  key={index}
                  className="border-b py-2 flex justify-between items-center"
                >
                  <span className="text-gray-800 font-medium">
                    {contact.name} {contact.surname}
                  </span>
                  <span className="text-blue-600">{contact.mobile}</span>
                </li>
              ))
            ) : (
              <p className="text-gray-500 text-center">No contacts added.</p>
            )}
          </ul>
        </div>


        

      </div>

        <div classname="absolute flex flex-col items-center ml-10 p-6 bg-gray-100 min-h-screen">

          <button
            onClick={handleClick}
            className="ml-72 mr-40 bg-black text-white">See Diaologue box</button>

          {tanisha &&
          <div>
          <div 
          onClick={handleback}
          className="absolute right-20"> X</div>

            <div className="mr-20 ml-20 text-center p-20 bg-green-400 ml-auto z-60 "> Hi Tanisha</div>
            </div>
          }

        </div>
    </>
  );
};

export default Practice;
