import React, { useState, useEffect } from "react";
import Collection from './Assets/images/Collection.webp';
import harryd from './Assets/images/harryd.webp';
import harrym from './Assets/images/harrym.webp';
import collectionm from './Assets/images/collectionm.webp';
import gokuc from './Assets/images/gokuc.png';
import narutom from './Assets/images/narutom.webp';
import narutod from './Assets/images/narutod.webp';
import lavenderd from './Assets/images/lavenderd.webp';
import lavenderm from './Assets/images/lavenderm.webp';
import gear5 from './Assets/images/gear5.png';

const ResponsiveCarousel = () => {
  const images = {
    mobile: [collectionm, harrym, gokuc, narutom, gear5, lavenderm],
    desktop: [
      Collection,
      harryd,
      lavenderd,
      narutod,
     ],
  };

  const isMobile = window.innerWidth < 768;
  const currentImages = isMobile ? images.mobile : images.desktop;

  const [currentSlide, setCurrentSlide] = useState(1);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const clonedImages = [
    currentImages[currentImages.length - 1],
    ...currentImages,
    currentImages[0],
  ];

  const slideWidth = 100 / clonedImages.length;

  const nextSlide = () => {
    if (isTransitioning) return;
    setIsTransitioning(true);
    setCurrentSlide((prevSlide) => prevSlide + 1);
  };

  const prevSlide = () => {
    if (isTransitioning) return;
    setIsTransitioning(true);
    setCurrentSlide((prevSlide) => prevSlide - 1);
  };

  useEffect(() => {
    if (!isTransitioning) return;

    const handleTransitionEnd = () => {
      setIsTransitioning(false);
      if (currentSlide === clonedImages.length - 1) {
        setCurrentSlide(1);
      } else if (currentSlide === 0) {
        setCurrentSlide(clonedImages.length - 2);
      }
    };

    const timer = setTimeout(handleTransitionEnd, 700);
    return () => clearTimeout(timer);
  }, [currentSlide, clonedImages.length, isTransitioning]);

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  // Touch handlers
  let touchStartX = 0;
  let touchEndX = 0;

  const handleTouchStart = (e) => {
    touchStartX = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    touchEndX = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    if (touchStartX - touchEndX > 50) {
      // Swipe left
      nextSlide();
    } else if (touchEndX - touchStartX > 50) {
      // Swipe right
      prevSlide();
    }
  };

  return (
    <div
      className="relative w-full h-auto p-2 bg-gray-50"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <h2 className="text-2xl md:text-4xl  text-center mb-2 text-orange-400">
        Trending
      </h2>

      <div className="relative w-full mx-auto overflow-hidden rounded-lg shadow-lg">
        <div
          className="flex transition-transform duration-500 ease-in-out"
          style={{
            width: `${clonedImages.length * 100}%`,
            transform: `translateX(-${(currentSlide * 100) / clonedImages.length}%)`,
            transition: isTransitioning ? "transform 0.5s ease-in-out" : "none",
          }}
        >
          {clonedImages.map((image, index) => (
            <div
              key={index}
              className="flex-shrink-0"
              style={{
                width: `${slideWidth}%`,
                backgroundImage: `url(${image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "500px",
              }}
            />
          ))}
        </div>

        <button
          className="absolute left-2 top-1/2 transform -translate-y-1/2 text-white rounded-full p-3 hover:bg-gray-700 transition"
          onClick={prevSlide}
        >
          &lt;
        </button>

        <button
          className="absolute right-2 top-1/2 transform -translate-y-1/2 text-white rounded-full p-3 hover:bg-gray-700 transition"
          onClick={nextSlide}
        >
          &gt;
        </button>
      </div>

      <div className="flex justify-center mt-4">
        {currentImages.map((_, index) => (
          <button
            key={index}
            className={`w-3 h-3 rounded-full mx-1 ${
              currentSlide - 1 === index ? "bg-gray-800" : "bg-gray-300"
            }`}
            onClick={() => setCurrentSlide(index + 1)}
          ></button>
        ))}
      </div>
    </div>
  );
};

export default ResponsiveCarousel;
