import React, { useState, useEffect } from "react";
import Design from './Designname';
import { Helmet } from "react-helmet";
import cover from './Assets/images/cover.webp';
import mainbg from './Assets/images/mainbg.webp';

const Cover = () => {
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const isMobile = window.innerWidth < 440;
 

  return (
    <>
    <div
    className="relative w-full h-screen group mt-[-5%] md:mt-20"
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
  >
    <div
      className="absolute inset-0 bg-cover bg-center"
      style={{
        backgroundImage: `url(${window.innerWidth < 440 ? cover : mainbg})`, // Dynamic background for mobile and desktop
      }}
    ></div>
    
     <div className="absolute inset-0 bg-black opacity-20 "></div>
      <div
        className={`absolute top-0 left-0 w-full h-full bg-black opacity-0 `}
      ></div>
      <div className="relative z-10 flex justify-center items-center w-full h-full">
        <div className="text-center text-white">
          <h1 className="text-4xl text-gray-300 font-bold uppercase tracking-wide leading-tight mb-4 transform transition-all duration-500 ease-in-out hover:scale-110  mt-60">
            Discover Your Streetwear Style
          </h1>
          <p className=" hidden md:block text-lg mb-8  transform transition-all duration-500 ease-in-out hover:scale-105">
            Fresh drops, exclusive collections. Get ready for the next trend.
          </p>
          <a
            href="/products"
            className="px-4 py-3 bg-[#FFB400] text-black text-xl rounded-lg shadow-lg transform transition-all duration-300 ease-in-out hover:scale-105 hover:bg-orange-400"
          >
            Shop Now
          </a>
        </div>
      </div>

      {/* 3D Hover Effect on Cover Image */}
      <div className={`absolute inset-0 bg-cover bg-center transition-transform duration-500 ease-in-out ${hovered ? 'transform rotate-3d' : ''}`} style={{ backgroundImage: cover }}></div>
   
    </div>
    </>
  );

  
};

export default Cover;



