import React from "react";

const Instagram = () => (
    <button
    className="flex justify-center mt-3 mr-auto ml-auto bg-gradient-to-r from-[#833ab4] to-[#fd1d1d] text-white text-lg font-medium rounded-md px-6 py-2 flex items-center gap-2 hover:from-[#9c44c4] hover:to-[#ff3838] transition-all duration-300"
    onClick={() => window.open("https://www.instagram.com/sleeviaofficial/", "_blank")}
  >
    {/* Instagram SVG Icon */}
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="white"
      viewBox="0 0 24 24"
      width="24"
      height="24"
    >
      <path d="M7.75 2h8.5C19.216 2 22 4.784 22 7.75v8.5C22 19.216 19.216 22 16.25 22h-8.5C4.784 22 2 19.216 2 16.25v-8.5C2 4.784 4.784 2 7.75 2zm0 2C5.678 4 4 5.678 4 7.75v8.5C4 18.322 5.678 20 7.75 20h8.5c2.072 0 3.75-1.678 3.75-3.75v-8.5C20 5.678 18.322 4 16.25 4h-8.5zm8.75 1.5c.69 0 1.25.56 1.25 1.25 0 .69-.56 1.25-1.25 1.25-.69 0-1.25-.56-1.25-1.25 0-.69.56-1.25 1.25-1.25zM12 7.25A4.75 4.75 0 0 1 16.75 12 4.75 4.75 0 0 1 12 16.75 4.75 4.75 0 0 1 7.25 12 4.75 4.75 0 0 1 12 7.25zm0 2A2.75 2.75 0 0 0 9.25 12 2.75 2.75 0 0 0 12 14.75 2.75 2.75 0 0 0 14.75 12 2.75 2.75 0 0 0 12 9.25z" />
    </svg>
    Follow us on Instagram
  </button>
);

export default Instagram;
