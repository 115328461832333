// import React, { useRef } from "react";
// import { BrowserRouter as Router, Route, Routes } from "react-router-dom"; // Import Router components
// import Navbar from './Navbar';
// import './App.css';
// import Cover from './Cover';
// import ProductDetails from './Order';
// import Cart from './Cart'; // Import CartPage component
// import AboutUs from './About';
// import Home from "./Home";

// const App = () => {
//   const productsRef = useRef(null);

//   const scrollToProducts = () => {
//     productsRef.current.scrollIntoView({ behavior: "smooth" });
//   };

//   return (
   
//       <div>
//         <Navbar scrollToProducts={scrollToProducts} />


//         {/* Define routes for product details and cart */}
//         <Routes>
//         <Route path="/" element={<Home/>} />
//         <Route path="/productdetails" element={<ProductDetails />} />
//         <Route path="/products" element={<ProductDetails />} />
//         <Route path="/cart" element={<Cart />} />
//         <Route path="/aboutus" element={<AboutUs />} />
//         </Routes>

        
//       </div>
    
//   );
// };

// export default App;


import React, { useRef } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"; // Import Router components
import Navbar from './Navbar';
import './App.css';
import Cover from './Cover';
import ProductDetails from './Order';
import Cart from './Cart'; // Import CartPage component
import AboutUs from './About';
import Home from "./Home";
import ContactUs from "./Contact";
import ScrollToTop from "./ScrollTop";

// Import the CartProvider to wrap your app with the context
import { CartProvider } from './CartContext';
import Details from "./Details";
import ProductsPage from "./Collection";
import OurProducts from "./OurProducts";

const App = () => {
  const productsRef = useRef(null);

  const scrollToProducts = () => {
    productsRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <CartProvider> {/* Wrap the entire app with CartProvider */}
      <div>
        <Navbar scrollToProducts={scrollToProducts} />

        {/* Define routes for product details and cart */}
        <ScrollToTop />
        <Routes>
          <Route  path="/" element={<Home />} />
          <Route path="/productdetails" element={<ProductDetails />} />
          <Route path="/products" element={<OurProducts/>} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/product/:id" element={<Details />} />
        </Routes>
      </div>
    </CartProvider>
  );
};

export default App;
