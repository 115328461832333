

import React, { useState } from "react";
import { Box, Typography, Select, MenuItem, InputLabel, FormControl, IconButton } from "@mui/material";
import { ShoppingCart } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useCart } from "./CartContext";  // Import the CartContext
import goku from "./Assets/images/goku.png";
import itachi from "./Assets/images/itachi.png";
import kakashi from "./Assets/images/kakashi.png";
import gojo from "./Assets/images/gojo.png";
import lavender from "./Assets/images/lavender.png";
import Footer from "./Footer";

// Demo product data
const products = [
  { id: 1, name: "Goku Oversize T-Shirt", price: "Rs 499", image: goku, sizes: ["XS","S", "M", "L", "XL"] },
  { id: 2, name: "Lavender Oversize T-Shirt", price: "Rs 499", image: lavender, sizes: ["XS", "S", "M", "L", "XL"] },
  { id: 3, name: "Itachi Oversize T-Shirt", price: "Rs 499", image: itachi, sizes: ["XS", "S", "M", "L", "XL"] },
  { id: 4, name: "Kakashi Oversize T-Shirt", price: "Rs 499", image: kakashi, sizes: ["XS", "S", "M", "L", "XL"] },
  { id: 5, name: "Satoru Gojo Oversize T-Shirt", price: "Rs 499", image: gojo, sizes: ["XS", "S", "M", "L", "XL"] },
];

const ProductDetails = () => {
  const [quantityMap, setQuantityMap] = useState({});
  const [disabledButtons, setDisabledButtons] = useState({}); // Track disabled state for each product
  const navigate = useNavigate();

  const { selectedProducts, handleAddToCart } = useCart(); // Use CartContext to manage cart data

  const handleQuantityChange = (productId, increment) => {
    setQuantityMap((prevState) => ({
      ...prevState,
      [productId]: Math.max(1, (prevState[productId] || 1) + increment),
    }));
  };

  const getTotalItemsInCart = () => {
    return selectedProducts.reduce((total, product) => total + product.quantity, 0);
  };

  const handleAddToCartClick = (product, size, quantity) => {
    handleAddToCart(product, size, quantity);

    // Disable the button for this product after adding to cart
    setDisabledButtons((prevState) => ({
      ...prevState,
      [product.id]: true,
    }));
  };

  return (
    <>
      <Box sx={{ padding: 2 }}>
        <Typography variant="h4" sx={{ fontWeight: "bold", marginBottom: 2 }}>
          Available Products
        </Typography>

        {products.map((product) => {
          const quantity = quantityMap[product.id] || 1;

          return (
            <Box
              key={product.id}
              sx={{
                border: "1px solid #ccc",
                padding: 2,
                marginBottom: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                borderRadius: "8px",
                flexDirection: "row",
                justifyContent: "space-between",
                textAlign: "left",
                flexWrap: "wrap",
                fontFamily: "'Roboto', sans-serif",
              }}
            >
              <div className="flex flex-row justify-between ">
                <img
                  src={product.image}
                  alt={product.name}
                  style={{ width: "100%", height: "auto", maxWidth: "150px", marginBottom: 10, marginRight: 2 }}
                />
                <div>
                  <div className="ml-2">
                    <Typography>{product.name}</Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        color: "#666",
                        maxWidth: "600px",
                        margin: "0 auto",
                        lineHeight: "1.8",
                      }}
                    >
                      Price: {product.price}
                    </Typography>
                  </div>

                  <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center", gap: 2, marginY: 2 }}>
                    <FormControl sx={{ minWidth: 80 }}>
                      <InputLabel>Size</InputLabel>
                      <Select
                        defaultValue={product.sizes[0]}
                        label="Size"
                        sx={{
                          padding: "0",
                          "& .MuiSelect-select": {
                            padding: "5px 14px",
                          },
                        }}
                       >
                        {product.sizes.map((size) => (
                          <MenuItem key={size} value={size}>
                            {size}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <button
                        onClick={() => handleQuantityChange(product.id, -1)}
                        disabled={quantity <= 1}
                        className="px-3 py-1 border border-gray-300 rounded"
                        >
                        -
                      </button>
                      <Typography>{quantity}</Typography>
                      <button
                        onClick={() => handleQuantityChange(product.id, 1)}
                        className="px-3 py-1 border border-gray-300 rounded"
                      >
                        +
                      </button>
                    </Box>
                  </Box>
                </div>
              </div>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "row", sm: "row" },

                  gap: 2,
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: { xs: 0, sm: 0 },
                  marginLeft: { xs: 3, sm: 3 }
                }}
                >
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    handleAddToCartClick(product, product.sizes[0], quantity);
                  }}
                  className={`px-4 py-2 text-white bg-[#FFB400] rounded hover:bg-[#FFB400] cursor-pointer ${disabledButtons[product.id] ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                  style={{ pointerEvents: disabledButtons[product.id] ? "none" : "auto" }}
                  >
                  {disabledButtons[product.id] ? "Added" : "Add to Cart"}
                </a>

                <a
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/cart", { state: { selectedProducts } });
                  }}
                  className="text-[#FFB400] cursor-pointer underline cursor-pointer"
                 >
                  Go to Cart
                  {getTotalItemsInCart() > 0 && (
                    <Typography
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        fontSize: "12px",
                        color: "white",
                        background: "red",
                        borderRadius: "50%",
                        padding: "2px 6px",
                      }}
                    >
                      {getTotalItemsInCart()}
                    </Typography>
                  )}
                </a>
              </Box>
            </Box>
          );
        })}
      </Box>
      <Footer />
    </>
  );
};

export default ProductDetails;
