import React from "react";
import { Box, Typography, Grid, Button, Avatar, Divider } from "@mui/material";
import M from './Assets/images/M.png';
import V from './Assets/images/V.png';
import S from './Assets/images/S.png';
import Footer from "./Footer";
import Instagram from "./Instagram";
import Practice from "./Practice";
const AboutUs = () => {
  return (
    <>
      <Box
        sx={{
          backgroundColor: "#f8f9fa",
          minHeight: "100vh",
          padding: "50px 20px",
          fontFamily: "'Roboto', sans-serif",
          marginTop: "15%"
        }}
      >
        {/* Hero Section */}
        <Box
          sx={{
            textAlign: "center",
            marginBottom: "50px",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontWeight: "bold",
              color: "#333",
              marginBottom: "20px",
            }}
          >
            About Us
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              color: "#666",
              maxWidth: "600px",
              margin: "0 auto",
              lineHeight: "1.8",
            }}
          >
            At <strong>SLEEVIA</strong>, we believe in the power of self-expression
            through fashion. Our oversized streetwear t-shirts are designed to
            inspire creativity, individuality, and confidence in every trendsetter.
          </Typography>
        </Box>

        {/* Mission and Vision Section */}
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                backgroundColor: "white",
                boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
                borderRadius: "10px",
                padding: "20px",
                textAlign: "center",
                border: "1px solid #FFB400"
              }}
            >
              <Avatar
                src={M}
                alt="Mission"
                sx={{
                  width: 80,
                  height: 80,
                  margin: "0 auto 20px auto",
                }}
              />
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", color: "#333", marginBottom: "10px" }}
              >
                Our Mission
              </Typography>
              <Typography sx={{ color: "#555", lineHeight: "1.6" }}>
                To create premium-quality, stylish, and trendy oversized t-shirts
                that resonate with today’s youth while embracing diversity and
                individuality.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                backgroundColor: "white",
                boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
                borderRadius: "10px",
                padding: "20px",
                textAlign: "center",
                border: "1px solid #FFB400"
              }}
            >
              <Avatar
                src={V}
                alt="Vision"
                sx={{
                  width: 80,
                  height: 80,
                  margin: "0 auto 20px auto",
                }}
              />
              <Typography
                variant="h5"
                sx={{ fontWeight: "bold", color: "#333", marginBottom: "10px" }}
              >
                Our Vision
              </Typography>
              <Typography sx={{ color: "#555", lineHeight: "1.6" }}>
                To become the leading brand in streetwear fashion, empowering
                individuals to wear their uniqueness and express their true
                selves.
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {/* Divider */}
        <Divider sx={{ margin: "50px 0" }} />

        {/* Story Section */}
        <Box
          sx={{
            backgroundColor: "white",
            boxShadow: "0 4px 15px rgba(0,0,0,0.1)",
            borderRadius: "10px",
            padding: "30px",
            border: "1px solid #FFB400",
          }}
        >
          <Avatar
            src={S}
            alt="Vision"
            sx={{
              width: 80,
              height: 80,
              margin: "0 auto 20px auto",
            }}
          />
          <Typography
            variant="h4"
            sx={{
              fontWeight: "bold",
              color: "#333",
              marginBottom: "20px",
              textAlign: "center",
            }}
          >
            Our Story
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: "#555",
              lineHeight: "1.8",
              textAlign: "center",
              maxWidth: "800px",
              margin: "0 auto",
            }}
          >
            <strong>SLEEVIA</strong> Ever since you watched your hero, couldn't get them out of your mind? We get you! To celebrate your passion, we bring you the latest apparel inspired by your favorite series, characters, and iconic moments.
            Join the movement, wear your fandom with pride, and stand out in a world that’s as vibrant and dynamic as the anime we adore. 

 *Wear your heart on your sleeves, 
Enter the fashionverse of Sleevia!*
          </Typography>
        </Box>

        {/* Call to Action */}
        <Box> <Instagram />  </Box>

        <Practice/>

      </Box>

      <Footer />
    </>
  );
};

export default AboutUs;
