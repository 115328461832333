import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { useNavigate } from "react-router-dom"; 
import gokub1 from './Assets/images/gokub1.jpg';
import itachi from './Assets/images/itachi.png';
import kakashi from './Assets/images/kakashi.png';
import gojo from './Assets/images/gojo.png';
import pain from './Assets/images/pain.png';
import potter1 from './Assets/images/potter1.png';
import potter2 from './Assets/images/potter2.png';
import lavender from './Assets/images/lavender.png';    

// Sample Product Data
const similarProducts = [
    { id: 1, name: "Goku Oversize T-Shirt", price: "Rs 499", image: gokub1 },
    { id: 2, name: "Lavender Oversize T-Shirt", price: "Rs 499", image: lavender },
    { id: 3, name: "Itachi Oversize T-shirt", price: "Rs 499", image: itachi },
    { id: 4, name: "Kakashi Oversize T-shirt", price: "Rs 499", image: kakashi },
    { id: 5, name: "Satoru Gojo Oversize T-shirt", price: "Rs 499", image: gojo },
    { id: 6, name: "Harry Potter Oversize T-shirt", price: "Rs 499", image: potter1 },
];

const SimilarProducts = () => {
    const scrollContainerRef = React.useRef(null);
    const navigate = useNavigate();
  
    // Scroll function
    const scroll = (direction) => {
      const container = scrollContainerRef.current;
      const scrollAmount = container.offsetWidth;
      container.scrollBy({ left: direction === "left" ? -scrollAmount : scrollAmount, behavior: "smooth" });
    };
  
    const handleCardClick = (productId) => {
        navigate(`/product/${productId}`); // Navigate to the details page with the product ID
    };

    return (
      <Box sx={{ padding: "16px 0", overflow: "hidden" }}>
   <Typography
  variant="h6"
  sx={{
    marginBottom: "16px",
    marginLeft:"3%",
    fontWeight: "bold",
    color: "#333",
    position: "relative", // Make sure the text has a relative position
    padding: "0 5px", // Adding padding to create space between text and borders
  }}
  className="relative mb-4 font-bold text-gray-800"
>
  Similar Products
 
</Typography>
  
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            position: "relative",
          }}
        >
          {/* Scroll Left Button */}
          <Button
            sx={{
              position: "absolute",
              left: 0,
              zIndex: 10,
              display: { xs: "none", sm: "block" },
              minWidth: "32px",
              height: "100%",
             overflow:"none"
            }}
            onClick={() => scroll("left")}
          >
            <ArrowBackIos />
          </Button>
  
          {/* Product Cards Container */}
          <Box
            ref={scrollContainerRef}
            sx={{
              display: "flex",
              overflowX: "auto",
              scrollBehavior: "smooth",
              scrollbarWidth: "none",
              "&::-webkit-scrollbar": { display: "none" }, // Hide scrollbar
              gap: "16px",
              padding: "0 16px",
            }}
          >
            {similarProducts.map((product) => (
              <Box
                key={product.id}
                onClick={() => handleCardClick(product.id)} 
                sx={{
                  flex: "0 0 calc(100% / 2.7)", // Ensures 3-4 products visible
                  maxWidth: "calc(100% / 2.7)",
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                  padding: "8px",
                  backgroundColor: "#fff",
                  textAlign: "center",
                  boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                  height:"fit-content"
                }}
              >
                <img
                  src={product.image}
                  alt={product.name}
                  style={{ maxWidth: "100%", height: "auto", borderRadius: "8px" }}
                />
                <Typography
                  variant="body2"
                  sx={{ marginTop: "8px", fontWeight: "", color: "#333" }}
                >
                  {product.name}
                </Typography>
                <Typography variant="" sx={{ color: "#fb8c00" }}>
                  {product.price}
                </Typography>
              </Box>
            ))}
          </Box>
            
          <Button
            sx={{
              position: "absolute",
              right: 0,
              zIndex: 10,
              display: {  xs: "none", sm: "block"  },
              minWidth: "32px",
              height: "100%",
              
            }}
            onClick={() => scroll("right")}
          >
            <ArrowForwardIos />
          </Button>
        </Box>
      </Box>
    );
  };
  
  export default SimilarProducts;