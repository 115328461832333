// CartIcon.jsx
import React from "react";
import { ShoppingCart } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useCart } from "./CartContext";

const CartIcon = ({ className = "" }) => {
  const navigate = useNavigate();
  const { selectedProducts, getTotalItemsInCart } = useCart();

  return (
    <div
      className={`flex items-center cursor-pointer mr-3 ${className}`}
      onClick={() => navigate("/cart", { state: { selectedProducts } })}
    >
      <div
        className="p-1 text-white bg-[#FFB400] rounded"
        style={{ position: "relative", borderRadius: "35%" }}
      >
        <ShoppingCart
          sx={{ backgroundColor: "#FFB400", borderRadius: "50%" }}
        />
        {getTotalItemsInCart() > 0 && (
          <span
            style={{
              position: "absolute",
              top: "-10px",
              right: "-1px",
              fontSize: "12px",
              fontWeight: "bold",
              color: "#fff",
              backgroundColor: "#FFB400",
              borderRadius: "50%",
              padding: "1px 6px",
            }}
          >
            {getTotalItemsInCart()}
          </span>
        )}
      </div>
    </div>
  );
};

export default CartIcon;
