import React from "react";
import Footer from "./Footer";
import Instagram from "./Instagram";
import contact from './Assets/images/contact.webp';
const ContactUs = () => {
  return (

    <>
    <div
      className="flex flex-col items-center justify-center bg-gray-100 min-h-screen p-4"
      style={{
        backgroundImage:
          "url('https://media.istockphoto.com/id/879441788/photo/web-contact-us-icons-on-cubes.jpg?s=612x612&w=0&k=20&c=4wKebqcelXRP3skkhI-SlTeJQekYavAKGrcwYm9awag=')",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",}}
    >
        <h1 className="text-2xl font-medium sm:text-2xl md:font-bold text-gray-800 mt-3 mb-6 ">
        Get in Touch
      </h1>

      {/* Cover Image */}
      <div className="relative w-cover transform transition-transform hover:scale-105 hover:shadow-xl">
  <img
    src={contact}
    alt="Contact Us Cover"
    className="w-full h-40 sm:h-60 object-cover rounded-lg shadow-md border border-gray-300 hover:border-gray-500"
  />
  <div className="absolute inset-0 rounded-lg bg-gradient-to-t from-white via-transparent to-transparent opacity-0 hover:opacity-80 transition-opacity"></div>
</div>

      {/* Title */}
      
      {/* Buttons Container */}
      <div className="flex flex-col items-center mt-4  space-y-auto">
        {/* WhatsApp Button */}
        <a
          href="https://wa.me/9026875429"
          target="_blank"
          rel="noopener noreferrer"
          className="w-full max-w-xs flex items-center justify-center  m-2 py-2 px-6 bg-green-500 hover:bg-green-600 text-white text-lg font-medium rounded-lg shadow-lg transition duration-300"
        >
           <img
              src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
              alt="WhatsApp Icon"
              className="w-5 h-5 mr-3"
            />
          WhatsApp Us
        </a>

        {/* Instagram Button */}
       <Instagram/>
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default ContactUs;
